import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';

import { orderActions } from './../store/slices/order';
import { authActions } from './../store/slices/auth';
import { marginActions } from './../store/slices/margin';
import { mainFilterActions } from './../store/slices/main-filter';
import { channelsFilterActions } from './../store/slices/channels-filter';
import { ordersFilterActions } from './../store/slices/orders-filter';

import { AppDispatch, RootState } from '../store/store';

import type { TypedUseSelectorHook } from 'react-redux';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const allActions = {
  ...authActions,
  ...orderActions,
  ...marginActions,
  ...mainFilterActions,
  ...channelsFilterActions,
  ...ordersFilterActions,
};

export const useActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch]);
};
