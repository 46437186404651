import { Button } from 'antd';
import Cookies from 'universal-cookie';

import { ACCESS_TOKEN_KEY, WIDGET_NAME_DEV, WIDGET_NAME_PROD } from '../../../features/constants';

const widgetName = process.env.NODE_ENV === 'development' ? WIDGET_NAME_DEV : WIDGET_NAME_PROD;

export const Logout = () => {
  const cookies = new Cookies();

  const handleLogout = () => {
    cookies.remove(ACCESS_TOKEN_KEY);
    document.getElementById(widgetName!)?.remove();
    window.location.reload();
  };

  return (
    <Button danger onClick={handleLogout}>
      Выйти
    </Button>
  );
};
