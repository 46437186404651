import { AnchorHTMLAttributes } from 'react';
import { useParams } from 'react-router-dom';

export const AWithLocale = (args: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { locale } = useParams();

  if (!locale) {
    console.log('Локали нет, нужен редирект на страницу с ошибкой');
    return null;
  }

  return <a {...args} href={`/${locale!}${args.href}`} />;
};
