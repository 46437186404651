import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { CreateOrderType, OrderSteps, OrderVariantType } from '../../../types/order';
import { ChannelsInOrderType } from '../../../types/channels';

import type { PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
  order: CreateOrderType;
  step: OrderSteps;
  order_variant: OrderVariantType | null;
}

const initialState: OrderState = {
  order: {
    amountCompany: 0,
    channels: [],
    endDate: null,
    frequency: 0,
    createdAt: null,
    creatorId: '',
    startDate: null,
    status: 'DRAFT',
    tz: '',
    link: 'https://google.com',
    linkName: 'test',
  },
  step: 'CHANNELS',
  order_variant: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setDates: (state, action: PayloadAction<{ startDate: string; endDate: string; tz: string }>) => {
      state.order.startDate = action.payload.startDate;
      state.order.endDate = action.payload.endDate;
      state.order.tz = action.payload.tz;
    },
    updateChannels: (state, action: PayloadAction<ChannelsInOrderType[]>) => {
      state.order.channels = action.payload;
    },
    updateStep: (state, action: PayloadAction<OrderSteps>) => {
      state.step = action.payload;
    },
    updateOrderVariant: (state, action: PayloadAction<OrderVariantType>) => {
      state.order_variant = action.payload;
    },
  },
});

export const orderActions = orderSlice.actions;

export const selectOrder = (state: RootState) => state.order;

export default orderSlice.reducer;
