import { LoginButton, TelegramAuthData } from '@telegram-auth/react';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Alert } from 'antd';

import { useGetToken } from '../../api/hooks/useGetToken';
import { useChechAuth } from '../../api/hooks/useCheckAuth';
import { Loader } from '../loader';
import {
  ACCESS_TOKEN_KEY,
  BOT_NAME_DEV,
  BOT_NAME_PROD,
  WIDGET_NAME_PROD,
  WIDGET_NAME_DEV,
} from '../../features/constants';
import { useActions } from '../../features/hooks/useRedux';

const botName = process.env.NODE_ENV === 'development' ? BOT_NAME_DEV : BOT_NAME_PROD;
const widgetName = process.env.NODE_ENV === 'development' ? WIDGET_NAME_DEV : WIDGET_NAME_PROD;

export default function PersonalAreaGuard({ children }: { children: React.ReactNode }) {
  const { mutateAsync: getTokenRequest } = useGetToken();
  const { isPending, data, refetch } = useChechAuth();

  const { setUser } = useActions();

  const cookies = new Cookies();

  const getTokenCallback = async (data: TelegramAuthData) => {
    try {
      if (!data) {
        return toast('Данные о пользователе telegram не получены', {
          type: 'error',
        });
      }

      const tokens = await getTokenRequest({
        auth_date: data.auth_date,
        first_name: data.first_name,
        hash: data.hash,
        id: data.id,
        last_name: data.last_name,
        photo_url: data.photo_url,
        username: data.username,
      });

      if (tokens) {
        cookies.set(ACCESS_TOKEN_KEY, tokens.accessToken, { expires: new Date(Date.now() + 60 * 60 * 1000 * 24 * 30) });

        document.getElementById(widgetName)?.remove();

        await refetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && data.user) {
      setUser(data?.user);
    }
  }, [data]);

  if (isPending) return <Loader />;

  if (data?.user) {
    return <>{children}</>;
  }

  return (
    <div>
      <LoginButton
        botUsername={botName}
        authCallbackUrl={undefined}
        buttonSize="large"
        cornerRadius={5}
        showAvatar={true}
        lang="ru"
        onAuthCallback={getTokenCallback}
      />
      <div className="mt-2">
        <Alert message="Удалить сессию можно только через telegram. Удалите сессию и перезагрузите страницу." />
      </div>
    </div>
  );
}
