import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { STORAGE_KEY_LANG_KEY } from '../../../features/constants';

const langs: any = {
  ru: { nativeName: 'Русский' },
  uz: { nativeName: "O'zbek" },
};

export default function LangSwitcher() {
  const { t, i18n } = useTranslation();

  const onChange = (i: string) => {
    localStorage.setItem(STORAGE_KEY_LANG_KEY, i);
    i18n.changeLanguage(i);
  };

  return (
    <div>
      <Select value={i18n.language} onChange={onChange}>
        {Object.keys(langs).map((lang) => (
          <Select.Option key={lang} value={lang}>
            {langs[lang].nativeName}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
