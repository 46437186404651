import { configureStore } from '@reduxjs/toolkit';

import orderReducer from './slices/order';
import authReducer from './slices/auth';
import marginReducer from './slices/margin';
import channelsFilterReducer from './slices/channels-filter';
import mainFilterReducer from './slices/main-filter';
import ordersFilterReducer from './slices/orders-filter';

export const store = configureStore({
  reducer: {
    order: orderReducer,
    auth: authReducer,
    margin: marginReducer,
    channelsFilter: channelsFilterReducer,
    mainFilter: mainFilterReducer,
    ordersFilter: ordersFilterReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
