import { useAppSelector } from '../../features/hooks/useRedux';
import { selectAuth } from '../../features/store/slices/auth';

interface CheckPermissionProps {
  permissionId: string;
  children?: React.ReactNode;
}
export const CheckPermissionContent = ({ permissionId, children }: CheckPermissionProps) => {
  const { user } = useAppSelector(selectAuth);

  if (!user?.role.permissions.some((permission) => permission.id === permissionId)) return null;

  return <>{children}</>;
};
