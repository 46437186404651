import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { App } from './App';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './features/store/store';

import './i18n';

const client = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>,
);
