import { TelegramAuthData } from '@telegram-auth/react';

import { request } from '../axios';
import {
  GetMarginParams,
  GetMarginResType,
  GetTransactionsResType,
  ResGetToken,
  UpdateTransactionsReqBody,
  CreateReportResType,
  GetReportResType,
  CheckTokenResType,
  GetPricesResType,
} from '../types/personal-area';

export const personalAreaService = {
  checkAuth: () => {
    return request.get<CheckTokenResType>('/personal-area/check').then((res) => res.data);
  },
  getToken: (data: TelegramAuthData) => {
    return request.post<ResGetToken>('/personal-area/token', data).then((res) => res.data);
  },
  getTransactions: ({ limit, offset }: { limit: number; offset: number }) => {
    return request
      .get<GetTransactionsResType>('/personal-area/transactions', { params: { limit, offset, type: 'WRITE_OFF' } })
      .then((res) => res.data);
  },
  updateTransactions: async (body: UpdateTransactionsReqBody) => {
    return request.post('/personal-area/transactions', body).then((res) => res.data);
  },
  getMargin: async (params: GetMarginParams) => {
    return request.get<GetMarginResType>('/personal-area/margin', { params }).then((res) => res.data);
  },
  createReport: async () => {
    return request.get<CreateReportResType>('/personal-area/report').then((res) => res.data);
  },
  getReport: async ({ limit, offset }: { limit: number; offset: number }) => {
    return request
      .get<GetReportResType>('/personal-area/report/all', { params: { limit, offset } })
      .then((res) => res.data);
  },
  getPrices: async () => {
    return request.get<GetPricesResType>('/personal-area/price-calculation').then((res) => res.data);
  },
  priceCalculation: async () => {
    return request.post('/personal-area/price-calculation').then((res) => res.data);
  },
};
