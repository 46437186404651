import { Header } from '../common/header';

interface RootLayoutProps {
  children?: React.ReactNode;
}
export const MobileLayout = ({ children }: RootLayoutProps) => {
  return (
    <div>
      <Header />
      <div className="container-mobile">{children}</div>
    </div>
  );
};
