import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { STORAGE_KEY_LANG_KEY } from './features/constants';

const language = localStorage.getItem(STORAGE_KEY_LANG_KEY);
if (!language) {
  localStorage.setItem(STORAGE_KEY_LANG_KEY, 'ru');
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem(STORAGE_KEY_LANG_KEY) || 'ru',
    debug: true,
    fallbackLng: ['uz', 'ru'],
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
