import { useParams } from 'react-router-dom';

export const NotLocale = ({ children }: { children: React.ReactNode }) => {
  const { locale } = useParams();

  if (!locale) {
    return <p>Не найдена локаль</p>;
  }

  return <>{children}</>;
};
