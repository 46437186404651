import { Header } from '../common/header';

interface RootLayoutProps {
  children?: React.ReactNode;
}
export const DesktopLayout = ({ children }: RootLayoutProps) => {
  return (
    <div>
      <Header />
      <div className="container">{children}</div>
    </div>
  );
};
