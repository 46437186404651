import { useMutation, useQuery } from '@tanstack/react-query';

import { personalAreaService } from './../services/personal-area';

import { QUERY_KEY_CHECK_AUTH } from '../../features/constants';

export const useChechAuth = () => {
  return useQuery({
    queryKey: [QUERY_KEY_CHECK_AUTH],
    queryFn: () => personalAreaService.checkAuth(),
  });
};
